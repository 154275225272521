
.content-area {
  width: 100%;
  background-color: red;
  text-align: center;
}

.login-area {
  background-color: green;
  padding-top: 100px;
  max-width: 300px;
}


.login-title{
    padding-top:20px;
    font-size:40px;
    font-weight:500;
    text-align:center;
    padding-bottom:30px;
}

.login-item {
  padding-top: 20px;
  font-size: 30px;
  background-color: #5260ff;
}

.login-input {
  font-size: 30px;

}

.login-colpadding {
  padding-left: 40px;
  padding-right: 40px;
}


.login-button-colpadding {
  padding-left: 20px;
  padding-right: 20px;
}


.login-button {
  font-size: 30px;
  color:lightgray;
  height: 80px;
  width: 100%;
  background-color: #4052B9;
}

ion-button {
  --background: #062f77;
}

.Height120 {
  height: 120px;
}

.googlePassionOne {
  font-family: 'Passion One', cursive !important;
  font-size: 40px !important;
  font-weight: 100;
}


.mycontainer {
  width: 350px;
  margin:auto;
  text-align: center !important;
  border: 10px solid #FFFFFF;
  padding:10px;
 /* margin-left:10px;
  margin-right:10px;*/
  border-radius: 8px;
  background-color: white;
}


/*.loginbackground {
  background-image: url("../pages/WasatchFrontVertical.png");
  background-repeat: no-repeat;
  background-size: cover;
}
*/
