/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

:root {
  --ion-color-time: #824459;
  --ion-color-time-rgb: 222, 59, 137;
  --ion-color-time-contrast: #ffffff;
  --ion-color-time-contrast-rgb: 255,255,255;
  --ion-color-time-shade: #824459;
  --ion-color-time-tint: #824459;
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105,187,123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255,255,255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;
  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29,161,244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;
  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89,86,216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255,255,255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;
  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35,182,234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255,255,255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
  --ion-color-theme: #1E96D4;
  --ion-color-theme-rgb: 30, 150, 212;
  --ion-color-theme-contrast: #ffffff;
  --ion-color-theme-contrast-rgb: 255, 255, 255;
  --ion-color-theme-shade: #156a96;
  --ion-color-theme-tint: #1E96D4;
  --ion-color-purple: #798af7;
  --ion-color-purple-rgb: 121, 138, 247;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #545fab;
  --ion-color-purple-tint: #798af7;
  --ion-color-bswift: #c34620;
  --ion-color-bswift-rgb: 195, 70, 32;
  --ion-color-bswift-contrast: #ffffff;
  --ion-color-bswift-contrast-rgb: 255, 255, 255;
  --ion-color-bswift-shade: #732811;
  --ion-color-bswift-tint: #c34620;
  --ion-color-handbook: #9fc7e9;
  --ion-color-handbook-rgb: 71, 158, 215;
  --ion-color-handbook-contrast: #ffffff;
  --ion-color-handbook-contrast-rgb: 255, 255, 255;
  --ion-color-handbook-shade: #465362;
  --ion-color-handbook-tint: #9fc7e9;
  --ion-color-fmla: #1c355c;
  --ion-color-fmla-rgb: 28, 53, 92;
  --ion-color-fmla-contrast: #ffffff;
  --ion-color-fmla-contrast-rgb: 255, 255, 255;
  --ion-color-fmla-shade: #1c355c;
  --ion-color-fmla-tint: #1c355c;
  --ion-color-mywhite: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
  --ion-color-lightpink: #ffccd4;
  --ion-color-lightpink-rgb: 255,204,212;
  --ion-color-lightpink-contrast: #000000;
  --ion-color-lightpink-contrast-rgb: 0,0,0;
  --ion-color-lightpink-shade: #e0b4bb;
  --ion-color-lightpink-tint: #ffd1d8;
  --ion-color-lightblue: #C8D8FEFF;
  --ion-color-lightblue-rgb: 216,254,255;
  --ion-color-lightblue-contrast: #000000;
  --ion-color-lightblue-contrast-rgb: 0,0,0;
  --ion-color-lightblue-shade: #bee0e0;
  --ion-color-lightblue-tint: #dcfeff;
  --ion-color-lightgreen: #CCFFC4;
  --ion-color-lightgreen-rgb: 204,255,196;
  --ion-color-lightgreen-contrast: #000000;
  --ion-color-lightgreen-contrast-rgb: 0,0,0;
  --ion-color-lightgreen-shade: #b4e0ac;
  --ion-color-lightgreen-tint: #d1ffca;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-lightblue1: #b1f2ff;
  --ion-color-lightblue1-rgb: 177,242,255;
  --ion-color-lightblue1-contrast: #000000;
  --ion-color-lightblue1-contrast-rgb: 0,0,0;
  --ion-color-lightblue1-shade: #9cd5e0;
  --ion-color-lightblue1-tint: #b9f3ff;
  /*--ion-color-icon: #ffffff;
    --ion-color-icon-rgb: 255, 255, 255;
    --ion-color-icon-contrast: #ffffff;
    --ion-color-icon-contrast-rgb: 255, 255, 255;
    --ion-color-icon-shade: #ffffff;
    --ion-color-icon-tint: #ffffff;*/

  --ion-color-red: #ffe0e0;
  --ion-color-red-rgb: 255,224,224;
  --ion-color-red-contrast: #000000;
  --ion-color-red-contrast-rgb: 0,0,0;
  --ion-color-red-shade: #e0c5c5;
  --ion-color-red-tint: #ffe3e3;
  --ion-color-green: #e0ffe6;
  --ion-color-green-rgb: 224,255,230;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0,0,0;
  --ion-color-green-shade: #c5e0ca;
  --ion-color-green-tint: #e3ffe9;
  --ion-color-blue: #e0ecff;
  --ion-color-blue-rgb: 224,236,255;
  --ion-color-blue-contrast: #000000;
  --ion-color-blue-contrast-rgb: 0,0,0;
  --ion-color-blue-shade: #c5d0e0;
  --ion-color-blue-tint: #e3eeff;
  --ion-color-comp-earned: #a31621;
  --ion-color-comp-earned-rgb: 163,22,33;
  --ion-color-comp-earned-contrast: #ffffff;
  --ion-color-comp-earned-contrast-rgb: 255,255,255;
  --ion-color-comp-earned-shade: #8f131d;
  --ion-color-comp-earned-tint: #ac2d37;
  --ion-color-comp-used: #ced3dc;
  --ion-color-comp-used-rgb: 206,211,220;
  --ion-color-comp-used-contrast: #000000;
  --ion-color-comp-used-contrast-rgb: 0,0,0;
  --ion-color-comp-used-shade: #b5bac2;
  --ion-color-comp-used-tint: #d3d7e0;
  --ion-color-holiday: #4e8098;
  --ion-color-holiday-rgb: 78,128,152;
  --ion-color-holiday-contrast: #ffffff;
  --ion-color-holiday-contrast-rgb: 255,255,255;
  --ion-color-holiday-shade: #457186;
  --ion-color-holiday-tint: #608da2;
  --ion-color-on-call: #4e8098;
  --ion-color-on-call-rgb: 78,128,152;
  --ion-color-on-call-contrast: #ffffff;
  --ion-color-on-call-contrast-rgb: 255,255,255;
  --ion-color-on-call-shade: #457186;
  --ion-color-on-call-tint: #608da2;
  --ion-color-other: #a9cbb7;
  --ion-color-other-rgb: 169,203,183;
  --ion-color-other-contrast: #000000;
  --ion-color-other-contrast-rgb: 0,0,0;
  --ion-color-other-shade: #95b3a1;
  --ion-color-other-tint: #b2d0be;
  --ion-color-overtime-paid: #f7ff58;
  --ion-color-overtime-paid-rgb: 247,255,88;
  --ion-color-overtime-paid-contrast: #000000;
  --ion-color-overtime-paid-contrast-rgb: 0,0,0;
  --ion-color-overtime-paid-shade: #d9e04d;
  --ion-color-overtime-paid-tint: #f8ff69;
  --ion-color-overtime: #ff934f;
  --ion-color-overtime-rgb: 255,147,79;
  --ion-color-overtime-contrast: #000000;
  --ion-color-overtime-contrast-rgb: 0,0,0;
  --ion-color-overtime-shade: #e08146;
  --ion-color-overtime-tint: #ff9e61;
  --ion-color-personal: #5e565a;
  --ion-color-personal-rgb: 94,86,90;
  --ion-color-personal-contrast: #ffffff;
  --ion-color-personal-contrast-rgb: 255,255,255;
  --ion-color-personal-shade: #534c4f;
  --ion-color-personal-tint: #6e676b;
  --ion-color-regular-diff-payrate: #297373;
  --ion-color-regular-diff-payrate-rgb: 41,115,115;
  --ion-color-regular-diff-payrate-contrast: #ffffff;
  --ion-color-regular-diff-payrate-contrast-rgb: 255,255,255;
  --ion-color-regular-diff-payrate-shade: #246565;
  --ion-color-regular-diff-payrate-tint: #3e8181;
  --ion-color-regular: #ff8552;
  --ion-color-regular-rgb: 255,133,82;
  --ion-color-regular-contrast: #000000;
  --ion-color-regular-contrast-rgb: 0,0,0;
  --ion-color-regular-shade: #e07548;
  --ion-color-regular-tint: #ff9163;
  --ion-color-salary-fixed-pay: #39393a;
  --ion-color-salary-fixed-pay-rgb: 57,57,58;
  --ion-color-salary-fixed-pay-contrast: #ffffff;
  --ion-color-salary-fixed-pay-contrast-rgb: 255,255,255;
  --ion-color-salary-fixed-pay-shade: #323233;
  --ion-color-salary-fixed-pay-tint: #4d4d4e;
  --ion-color-sick: #cd8b76;
  --ion-color-sick-rgb: 205,139,118;
  --ion-color-sick-contrast: #000000;
  --ion-color-sick-contrast-rgb: 0,0,0;
  --ion-color-sick-shade: #b47a68;
  --ion-color-sick-tint: #d29784;
  --ion-color-vacation: #27474e;
  --ion-color-vacation-rgb: 39,71,78;
  --ion-color-vacation-contrast: #ffffff;
  --ion-color-vacation-contrast-rgb: 255,255,255;
  --ion-color-vacation-shade: #223e45;
  --ion-color-vacation-tint: #3d5960;
}

.ion-color-vacation {
  --ion-color-base: var(--ion-color-vacation);
  --ion-color-base-rgb: var(--ion-color-vacation-rgb);
  --ion-color-contrast: var(--ion-color-vacation-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vacation-contrast-rgb);
  --ion-color-shade: var(--ion-color-vacation-shade);
  --ion-color-tint: var(--ion-color-vacation-tint);
}

.ion-color-sick {
  --ion-color-base: var(--ion-color-sick);
  --ion-color-base-rgb: var(--ion-color-sick-rgb);
  --ion-color-contrast: var(--ion-color-sick-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sick-contrast-rgb);
  --ion-color-shade: var(--ion-color-sick-shade);
  --ion-color-tint: var(--ion-color-sick-tint);
}

.ion-color-salary-fixed-pay {
  --ion-color-base: var(--ion-color-salary-fixed-pay);
  --ion-color-base-rgb: var(--ion-color-salary-fixed-pay-rgb);
  --ion-color-contrast: var(--ion-color-salary-fixed-pay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-salary-fixed-pay-contrast-rgb);
  --ion-color-shade: var(--ion-color-salary-fixed-pay-shade);
  --ion-color-tint: var(--ion-color-salary-fixed-pay-tint);
}

.ion-color-regular {
  --ion-color-base: var(--ion-color-regular);
  --ion-color-base-rgb: var(--ion-color-regular-rgb);
  --ion-color-contrast: var(--ion-color-regular-contrast);
  --ion-color-contrast-rgb: var(--ion-color-regular-contrast-rgb);
  --ion-color-shade: var(--ion-color-regular-shade);
  --ion-color-tint: var(--ion-color-regular-tint);
}

.ion-color-regular-diff-payrate {
  --ion-color-base: var(--ion-color-regular-diff-payrate);
  --ion-color-base-rgb: var(--ion-color-regular-diff-payrate-rgb);
  --ion-color-contrast: var(--ion-color-regular-diff-payrate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-regular-diff-payrate-contrast-rgb);
  --ion-color-shade: var(--ion-color-regular-diff-payrate-shade);
  --ion-color-tint: var(--ion-color-regular-diff-payrate-tint);
}

.ion-color-personal {
  --ion-color-base: var(--ion-color-personal);
  --ion-color-base-rgb: var(--ion-color-personal-rgb);
  --ion-color-contrast: var(--ion-color-personal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-personal-contrast-rgb);
  --ion-color-shade: var(--ion-color-personal-shade);
  --ion-color-tint: var(--ion-color-personal-tint);
}

.ion-color-overtime {
  --ion-color-base: var(--ion-color-overtime);
  --ion-color-base-rgb: var(--ion-color-overtime-rgb);
  --ion-color-contrast: var(--ion-color-overtime-contrast);
  --ion-color-contrast-rgb: var(--ion-color-overtime-contrast-rgb);
  --ion-color-shade: var(--ion-color-overtime-shade);
  --ion-color-tint: var(--ion-color-overtime-tint);
}

.ion-color-overtime-paid {
  --ion-color-base: var(--ion-color-overtime-paid);
  --ion-color-base-rgb: var(--ion-color-overtime-paid-rgb);
  --ion-color-contrast: var(--ion-color-overtime-paid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-overtime-paid-contrast-rgb);
  --ion-color-shade: var(--ion-color-overtime-paid-shade);
  --ion-color-tint: var(--ion-color-overtime-paid-tint);
}

.ion-color-other {
  --ion-color-base: var(--ion-color-other);
  --ion-color-base-rgb: var(--ion-color-other-rgb);
  --ion-color-contrast: var(--ion-color-other-contrast);
  --ion-color-contrast-rgb: var(--ion-color-other-contrast-rgb);
  --ion-color-shade: var(--ion-color-other-shade);
  --ion-color-tint: var(--ion-color-other-tint);
}

.ion-color-on-call {
  --ion-color-base: var(--ion-color-on-call);
  --ion-color-base-rgb: var(--ion-color-on-call-rgb);
  --ion-color-contrast: var(--ion-color-on-call-contrast);
  --ion-color-contrast-rgb: var(--ion-color-on-call-contrast-rgb);
  --ion-color-shade: var(--ion-color-on-call-shade);
  --ion-color-tint: var(--ion-color-on-call-tint);
}

.ion-color-holiday {
  --ion-color-base: var(--ion-color-holiday);
  --ion-color-base-rgb: var(--ion-color-holiday-rgb);
  --ion-color-contrast: var(--ion-color-holiday-contrast);
  --ion-color-contrast-rgb: var(--ion-color-holiday-contrast-rgb);
  --ion-color-shade: var(--ion-color-holiday-shade);
  --ion-color-tint: var(--ion-color-holiday-tint);
}

.ion-color-comp-used {
  --ion-color-base: var(--ion-color-comp-used);
  --ion-color-base-rgb: var(--ion-color-comp-used-rgb);
  --ion-color-contrast: var(--ion-color-comp-used-contrast);
  --ion-color-contrast-rgb: var(--ion-color-comp-used-contrast-rgb);
  --ion-color-shade: var(--ion-color-comp-used-shade);
  --ion-color-tint: var(--ion-color-comp-used-tint);
}

.ion-color-comp-earned {
  --ion-color-base: var(--ion-color-comp-earned);
  --ion-color-base-rgb: var(--ion-color-comp-earned-rgb);
  --ion-color-contrast: var(--ion-color-comp-earned-contrast);
  --ion-color-contrast-rgb: var(--ion-color-comp-earned-contrast-rgb);
  --ion-color-shade: var(--ion-color-comp-earned-shade);
  --ion-color-tint: var(--ion-color-comp-earned-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-time {
  --ion-color-base: var(--ion-color-time);
  --ion-color-base-rgb: var(--ion-color-time-rgb);
  --ion-color-contrast: var(--ion-color-time-contrast);
  --ion-color-contrast-rgb: var(--ion-color-time-contrast-rgb);
  --ion-color-shade: var(--ion-color-time-shade);
  --ion-color-tint: var(--ion-color-time-tint);
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-theme {
  --ion-color-base: var(--ion-color-theme);
  --ion-color-base-rgb: var(--ion-color-theme-rgb);
  --ion-color-contrast: var(--ion-color-theme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
  --ion-color-shade: var(--ion-color-theme-shade);
  --ion-color-tint: var(--ion-color-theme-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-bswift {
  --ion-color-base: var(--ion-color-bswift);
  --ion-color-base-rgb: var(--ion-color-bswift-rgb);
  --ion-color-contrast: var(--ion-color-bswift-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bswift-contrast-rgb);
  --ion-color-shade: var(--ion-color-bswift-shade);
  --ion-color-tint: var(--ion-color-bswift-tint);
}

.ion-color-handbook {
  --ion-color-base: var(--ion-color-handbook);
  --ion-color-base-rgb: var(--ion-color-handbook-rgb);
  --ion-color-contrast: var(--ion-color-handbook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-handbook-contrast-rgb);
  --ion-color-shade: var(--ion-color-handbook-shade);
  --ion-color-tint: var(--ion-color-handbook-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-lightpink {
  --ion-color-base: var(--ion-color-lightpink);
  --ion-color-base-rgb: var(--ion-color-lightpink-rgb);
  --ion-color-contrast: var(--ion-color-lightpink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightpink-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightpink-shade);
  --ion-color-tint: var(--ion-color-lightpink-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

.ion-color-lightgreen {
  --ion-color-base: var(--ion-color-lightgreen);
  --ion-color-base-rgb: var(--ion-color-lightgreen-rgb);
  --ion-color-contrast: var(--ion-color-lightgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgreen-shade);
  --ion-color-tint: var(--ion-color-lightgreen-tint);
}


.ion-color-lightblue1 {
  --ion-color-base: var(--ion-color-lightblue1);
  --ion-color-base-rgb: var(--ion-color-lightblue1-rgb);
  --ion-color-contrast: var(--ion-color-lightblue1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue1-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue1-shade);
  --ion-color-tint: var(--ion-color-lightblue1-tint);
}


/*.ion-color-icon {
    --ion-color-base: var(--ion-color-icon);
    --ion-color-base-rgb: var(--ion-color-icon-rgb);
    --ion-color-contrast: var(--ion-color-icon-contrast);
    --ion-color-contrast-rgb: var(--ion-color-icon-contrast-rgb);
    --ion-color-shade: var(--ion-color-icon-shade);
    --ion-color-tint: var(--ion-color-icon-tint);
}*/

.ion-color-fmla {
  --ion-color-base: var(--ion-color-fmla);
  --ion-color-base-rgb: var(--ion-color-fmla-rgb);
  --ion-color-contrast: var(--ion-color-fmla-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fmla-contrast-rgb);
  --ion-color-shade: var(--ion-color-fmla-shade);
  --ion-color-tint: var(--ion-color-fmla-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211F1F;
  --ion-color-base-rgb: 33,31,31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537BC;
  --ion-color-base-rgb: 149,55,188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;
  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;
  /*  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;*/

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  --ion-color-theme: #0d4866;
  --ion-color-theme-rgb: 13, 72, 102;
  --ion-color-theme-contrast: #ffffff;
  --ion-color-theme-contrast-rgb: 255, 255, 255;
  --ion-color-theme-shade: #0a3145;
  --ion-color-theme-tint: #0d4866;
  --ion-color-purple: #545fab;
  --ion-color-purple-rgb: 84, 95, 171;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #545fab;
  --ion-color-purple-tint: #798af7;
  --ion-color-bswift: #732811;
  --ion-color-bswift-rgb: 195, 70, 32;
  --ion-color-bswift-contrast: #ffffff;
  --ion-color-bswift-contrast-rgb: 255, 255, 255;
  --ion-color-bswift-shade: #732811;
  --ion-color-bswift-tint: #732811;
  --ion-color-handbook: #465362;
  --ion-color-handbook-rgb: 71, 158, 215;
  --ion-color-handbook-contrast: #ffffff;
  --ion-color-handbook-contrast-rgb: 255, 255, 255;
  --ion-color-handbook-shade: #465362;
  --ion-color-handbook-tint: #465362;
  --ion-color-red: #400000;
  --ion-color-red-rgb: 64,0,0;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #380000;
  --ion-color-red-tint: #531a1a;
  --ion-color-green: #002e12;
  --ion-color-green-rgb: 0,46,18;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #002810;
  --ion-color-green-tint: #1a432a;
  --ion-color-blue: #01164d;
  --ion-color-blue-rgb: 1,22,77;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #011344;
  --ion-color-blue-tint: #1a2d5f;
}

  /*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

  .dark-theme.ios {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: #0d0d0d;
    --ion-item-background: #1e1e1e;
  }

  /*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

  .dark-theme.md {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
  }
