.RightAlignment {
  text-align: right;
}

.LeftAlignment {
  text-align: left;
}

.CenterAlignment {
  text-align: center;
}


.TextAreaPadding {
    padding-top:10px;
    padding-bottom:10px;
}

.Height20{
    height:20px;
}

.AlignBottom {
  position: fixed;
  left: 0;
  bottom: 10px;
  right: 0;
}

.SubmitButtonItem{
    padding-top:10px;
    height:50px;
}


.card-footer {
  padding-top:0px;
  position: absolute;
  padding-left:0px;
  padding-right:10px;
  bottom: 10px;
  width: 100%;
}

.card-submit-button {
  text-align:center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 16px;
  height: 50px !important;
  bottom: 10px;
  width: 100%;
}

.circle {
   margin-top:0px;
  border-radius: 50%;
  width: 15px;
  height: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background: #ffff;
  border: 3px solid #000;
  color: #000;
  text-align: center;
  font: 10px Arial;
}

.hiddenLabel {
  display: none;
}


li {
    list-style-type:none;
  cursor: pointer;
}

.hr {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}



/*
.googleOswald {
  font-family: 'Oswald', sans-serif;
}

.googlePatua {
  font-family: 'Patua One', cursive;
  transform: scaleY(1.5);
  font-weight: 400 !important;
}
*/
/*.googleOswald {
  font-family: 'Oswald', sans-serif !important;
  font-size: 26px !important;
  transform: scaleY(1.5);
  font-weight: 800 !important;
}

*/
/** this popover is import for single select ion-select **/
.popover-content {
  width: 400px !important;
}

/** This is the corner badge **/
/*https://www.cssportal.com/css-ribbon-generator/*/
.box {
  width: 100%;
  height: 40px;
  position: relative;
  border: none;
  background: transparent;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }

    .ribbon span::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #79A70A;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79A70A;
    }

    .ribbon span::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #79A70A;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79A70A;
    }


    /** New Corner Badge **/
/*https://codepen.io/alexmccabe/pen/zrlpd*/

/*


.arrow-left {
  padding-top: 50px;
  background-color: #ff0000;
  box-shadow: 3px 2px 0 0 rgba(255,99,71,0.8);
  height: 80px;
  left: -50px;
  position: absolute;
  top: -50px;
  width: 100px;
  -webkit-transform: rotate(-45deg);
}

.arrow-left span {
  color: #f5f5f5;
  font-family: sans-serif;
  font-size: 1.005em;
  left: 28px;
  top: 60px;
  position: absolute;
  width: 80px;
}


.arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid red;
  border-left: 60px solid transparent;
}

.arrow-right span {
    transform: rotate(45deg);
    background-color: pink;
  }
*/

/*
.rightbox {
  bottom: -100px;
  left: -100px;
  position: absolute;
  height: 200px;
  width: 200px;
  transform: rotate(45deg);
  background: linear-gradient(90deg, hsla(0, 0%, 0%, 1) 0%, hsla(284, 75%, 54%, 1) 100%);
  overflow: hidden;
}

.rightcorner {
  width: 100%;
  height: 100px;
  background:
      linear-gradient(to bottom left, red 50%,transparent 50%) 100% 0/50px 50px,
      linear-gradient(to bottom right, transparent 50%,transparent 50%) 0 0/50px 50px,
      linear-gradient(to bottom right, transparent 50%,transparent 50%) 0 0/60px 60px,
      linear-gradient(to bottom left, transparent 50%,transparent 50%) 100% 0/60px 60px, black;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  font-size: 40px;
}
*/
/*TopRight*/

.topRightTriangle {
  right: 0px;
  width: 100%;
  height: 10px;
  position: sticky ;
}
  .topRightTriangle::before,
  .topRightTriangle::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }

  .topRightTriangle::before,
  .topRightTriangle::before {
    border-width: 0.6em;
    border-right-color: #ccc;
    border-top-color: #ccc;
  }

  .topRightTriangle::after,
  .topRightTriangle::after {
    border-width: 1.5em;
    border-right-color: red;
    border-top-color: red;
  }


.adtext {
  position: absolute;
  background-color: transparent;
  padding: 16px;
  font-weight: bold;
  color:white;
}
.adtext.top-right {
    transform-origin: top right;
    right: 35px;
    top: -10px;
    /*transform: rotate(-45deg) translateY(100%);*/
    transform: rotate(45deg) translateX(100%)
  }




.form-wrapper-outer {
  padding: 40px;
  border-radius: 8px;
  margin: auto;
  width: 460px;
  border: 1px solid #DADCE0;
  margin-top: 7%;
}

  .form-wrapper-outer .form-logo {
    margin: 0px auto 15px;
    width: 100px;
  }

    .form-wrapper-outer .form-logo img {
      width: 100%;
    }

.form-greeting {
  text-align: center;
  font-size: 25px;
  margin-bottom: 15px;
}

.form-button {
  text-align: right;
}

.field-wrapper {
  position: relative;
  margin-bottom: 15px;
}

.field-wrapper input {
    border: 1px solid #DADCE0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
}

/*ion-card {
  border-radius: 5px !important;
}
*/





