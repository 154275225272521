.AlignBottom {
  position: fixed;
  left: 0px;
  bottom: 10px;
  right: 0px;
}

.AlignCenter {
  left: 0px;
  bottom: 10px;
  right: 0px;
  text-align:center;
  white-space:nowrap;
}


.AlignRight {
  text-align: right;
}


.PrewrapText {
  padding-top: 0;
  white-space: pre-wrap;
}

.Height20{
    height:120px;
}


.card-footer {
  padding-top: 0px;
  position: absolute;
  padding-left: 0px;
  padding-right: 30px;
  bottom: 10px;
  width: 100%;
}

.card-submit-button {
  text-align: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 16px;
  height: 50px !important;
  bottom: 10px;
  width: 100%;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
  white-space: pre-line !important;
}

.uploadButton
{
    height:40px; width:150px;
    font-family: sans-serif;
    font-size:20px;
}
ion-icon {
  pointer-events: none;
}




/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .modal-wrapper {
  background: #0094ff;
  height:430px;
  width:380px;
}


.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  text-align: center;
  padding:2px;
  height:50px;
  color:darkgrey;
}

  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 4px 3px 0 rgba(0,0,0,0.2);
  }

/* Add some padding inside the card container */
.container {
    
 /*padding: 2px 16px;*/
}

/*.googleOswald {
  font-family: 'Oswald', sans-serif !important;
  font-size: 18px !important;
  font-weight:600;
}
.googleLato {
  font-family: 'Lato', sans-serif !important;
  font-size: 18px !important;
  font-weight: 800;
}
.googleRoboto {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 12px !important;
  font-weight: 600;
}
.googleRobotoTitle {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 18px !important;
  font-weight: 800;
}
.googleArchivo {
  font-family: 'Archivo Narrow', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400;
}
*/

.fieldset {
  width: 300px;
  border-radius: 5px;
  position: relative;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 10px;
}

  .fieldset h6 {
    position: absolute;
    top: 0;
    color:darkgray;
    line-height: 1;
    margin: -9px 0 0; /* half of font-size */
    background: #fff;
    padding: 0 3px;
  }



/* ========================================================================================================================================
    start dropdown css style section
 ======================================================================================================================================== */

:root {
  --select-border: #777;
  --select-focus: green;
  --select-arrow: var(--select-border);
}



select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0px 14px 0 14px;
  margin: 0;
  width: 100%;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

  select::-ms-expand {
    display: none;
  }

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

  .select select, .select::after {
    grid-area: select;
  }

  .select:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

select[multiple] {
  padding-right: 0;
  height: 6rem;
}

  select[multiple] option {
    white-space: normal;
    outline-color: var(--select-focus);
  }

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}


/* ========================================================================================================================================
    end of  dropdown css style section
 ======================================================================================================================================== */
